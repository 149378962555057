<template>
    <div class="MyAlert">
        <div class="MyAlert__title" v-if="title || $slots.title">
            <slot name="title">{{ title }}</slot>
        </div>
        <div class="MyAlert__message" v-if="message || $slots.message">
            <slot name="message">{{ message }}</slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MyAlert",
        props: {
            title: String,
            message: String,
        },
    }
</script>

<style lang="scss" scoped>
    .MyAlert {
        background: #1677FF;
        padding: 10px 16px;
        display: flex;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 20px;
        &__title {
            margin-right: 16px;
            width: 30%;
        }
        &__message {
            width: 70%;
        }
    }
</style>
